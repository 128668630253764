import IReport from '@/types/IReport';
import IReportStatusSpec from '@/types/IReportStatusSpec';
import IReportRow from '@/types/IReportRow';

enum ReportStatus {
  submitted = 'SUBMITTED',
  inProgress = 'IN_PROGRESS',
  cancelled = 'CANCELLED',
  done = 'DONE',
  doneNoData = 'DONE_NO_DATA',
  saved = '_SAVED_',
  calculated = '_CALCULATED_',
  sent = '_SENT_',
}

const getReportStatusSpec = (status: ReportStatus): IReportStatusSpec => {
  switch (status) {
    case ReportStatus.submitted:
      return {
        label: 'Zgłoszony',
        color: '#607D8B',
      };
    case ReportStatus.inProgress:
      return {
        label: 'W trakcie',
        color: '#2196F3',
      };
    case ReportStatus.cancelled:
      return {
        label: 'Anulowany',
        color: '#F44336',
      };
    case ReportStatus.done:
      return {
        label: 'Pobrany',
        color: '#4CAF50',
      };
    case ReportStatus.doneNoData:
      return {
        label: 'Gotowy (brak danych)',
        color: '#8BC34A',
      };
    case ReportStatus.saved:
      return {
        label: 'Zapisany',
        color: '#CDDC39',
      };
    case ReportStatus.calculated:
      return {
        label: 'Obliczony',
        color: '#00BCD4',
        canDownloadReport: true,
        canShowReport: true,
      };
    case ReportStatus.sent:
      return {
        label: 'Wyslany',
        color: '#9C27B0',
        canDownloadReport: true,
        canShowReport: true,
      };
    default:
      return {
        label: '',
        color: '',
      };
  }
};

enum TransactionType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  B2B = 'B2B',
  NON_EU = 'NON_EU',
  DISPLACEMENT = 'DISPLACEMENT',
  REVERSE_CHARGE = 'REVERSE_CHARGE'
}

const getType = (type: TransactionType): string => {
  switch (type) {
    case TransactionType.DAILY:
      return 'Dzienny';
    case TransactionType.MONTHLY:
      return 'Sprzedaż lokalna VAT';
    case TransactionType.B2B:
      return 'B2B';
    case TransactionType.NON_EU:
      return 'Non EU - Eksport';
    case TransactionType.DISPLACEMENT:
      return 'Przemieszczanie';
    case TransactionType.REVERSE_CHARGE:
      return 'Reverse Change';
    default:
      return '';
  }
};

export default class ReportFactory {
  static toReport(data: any): IReport {
    return {
      id: data.reportId,
      generationDate: data?.generationDate?.replace('T', ' '),
      month: data.month,
      reportId: data.reportId,
      rowsQuantity: data.rowsQuantity,
      status: getReportStatusSpec(data.status),
      version: data.version,
      year: data.year,
    };
  }

  static toReportRow(data: any): IReportRow {
    return {
      grossCountry: data.grossCountry,
      netCountry: data.netCountry,
      taxCountry: data.taxCountry,
      activityId: data.activityId,
      buyerNumber: data.buyerNumber,
      countryFrom: data.countryFrom,
      countryTaxable: data.countryTaxable,
      countryTaxableCurrency: data.countryTaxableCurrency,
      countryTaxableCurrencyCost: data.countryTaxableCurrencyCost,
      countryTaxableTax: data.countryTaxableTax * 100,
      countryTo: data.countryTo,
      currency: data.currency,
      currencyCost: data.currencyCost,
      date: data.date,
      gross: data.gross,
      grossPLN: data.grossPLN,
      id: data.id,
      identifier: data.identifier,
      invoiceNumber: data.invoiceNumber,
      net: data.net,
      netPLN: data.netPLN,
      qty: data.qty,
      reportId: data.reportId,
      tax: data.tax,
      taxPLN: data.taxPLN,
      transactionType: data.transactionType,
      type: getType(data.type),
    };
  }
}
