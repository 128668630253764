
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import IReportsListParams from '@/types/IReportsListParams';
import IReport from '@/types/IReport';
import FileService from '@/services/FileService';
import ReportRepository from '@/repositories/ReportRepository';
import {resolveError} from '@/utils/notifications';
import ReportShow from '@/components/Report/ReportInfoTable.vue';

@Component({
  name: 'ReportTable',
  components: {ReportShow},
})
export default class ReportTable extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  params!: IReportsListParams;

  @Prop()
  data!: IReport[];

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_ID',
      value: 'id',
    },
    {
      text: 'SYSTEM_DATE',
      value: 'date',
      sortable: false,
    },
    {
      text: 'SYSTEM_VERSION',
      value: 'version',
    },
    {
      text: 'SYSTEM_STATUS',
      value: 'status.color',
    },
    {
      text: 'SYSTEM_GENERATION_DATE',
      value: 'generationDate',
    },
    {
      text: 'SYSTEM_ACTIONS',
      value: 'actions',
      sortable: false,
    },
  ];

  async downloadReport(report: IReport) {
    try {
      Vue.set(report, 'isReportDownloading', true);
      const {data} = await ReportRepository.downloadReport(report.id);
      const file = new FileService(data);
      file.downloadFile(`Report ${report.year}_${String(report.month).padStart(2, '0')}`);
    } catch (e) {
      resolveError(e, 'download');
    } finally {
      Vue.set(report, 'isReportDownloading', false);
    }
  }

  @Emit('paginationChanged')
  paginationChanged(page: number) {
    return page;
  }
}
