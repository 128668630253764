
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import FileUploadButton from "@/components/FileUploadButton.vue";
import Loader from "@/components/Loader.vue";
import ReportService from "@/services/ReportService";
import {NotificationTypes, notify, resolveError} from "@/utils/notifications";

@Component({
  name: 'ReportImportModal',
  components: {FileUploadButton, Loader}
})
export default class ReportImportModal extends Vue {
  @Prop()
  value!: boolean;
  @Prop()
  year!: number | string;
  @Prop()
  month!: number | string;

  fileUploading: boolean = false

  @Emit('input')
  toggleVisibility() {
    return !this.value;
  }

  @Emit('saved')
  onSaved() {
    this.toggleVisibility()
  }

  async uploadFile(file: File) {
    this.fileUploading = true
    try {
      await ReportService.importReport(this.year, this.month, file)
      notify(NotificationTypes.success, 'File uploaded')
      this.$emit('fileUploaded')
    } catch (e) {
      resolveError(e, 'upload')
    } finally {
      this.fileUploading = false
      this.toggleVisibility()
    }
  }

}
