

import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'FileUploadButton'
})
export default class FileUploadButton extends Vue {
  @Prop()
  accept!: string

  isSelecting: boolean = false

  onButtonClick() {
    this.isSelecting = true
    window.addEventListener('focus', () => {
      this.isSelecting = false
    }, { once: true })

    // @ts-ignore
    this.$refs.uploader.click()
  }

  @Emit('change')
  onFileChanged(e: any) {
    return e.target.files[0]
  }
}
