import axios from 'axios';

export default class ReportRepository {
  static getAll(year: string | number, month: string | number) {
    return axios.get(`mws/report/${year}/${month}`);
  }

  static getReport(reportId: number | string) {
    return axios.get(`mws/report/${reportId}`);
  }

  static async downloadReport(reportId: number) {
    return axios.get(`mws/report/${reportId}/excel`, {responseType: 'blob'});
  }

  static async generateReport(year: string | number, month: string | number) {
    return axios.post(`mws/report/request/${year}/${month}`);
  }

  static async importCosts(year: string | number, month: string | number, formData: FormData) {
    return axios.post(`mws/report/file/${year}/${month}`, formData)
  }
}
